import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Header from "../Header/index";
import Sidebar from "../Sidebar/index";
import PageTitle from "../PageTitle";
import Calendar from "../../../page/admin/Calendar";
import Profile from "../../../page/admin/Profile";
import InvitationsFormAdmin from "../../../page/admin/Form/InvitationsFormAdmin";
import Report from "../../../page/admin/Report";
import Dashboard from "../../../page/admin/Dashboard";
import InvitationsShow from "../../../page/admin/invitations/InvitationsShow";
import InvitationsEdit from "../../../page/admin/invitations/InvitationsEdit";
import Opd from "../../../page/admin/Opd";
import OpdShow from "../../../page/admin/Opd/OpdShow";
import OpdEdit from "../../../page/admin/Opd/OpdEdit";

const DefaultLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  useLocation();

  return (
    <div>
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content Area */}
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          {/* Header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {/* Main Content */}
          <main>
            <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
              <Routes>
                <Route
                  path="/dashboard"
                  element={
                    <>
                      <PageTitle title="Dashboard | SIMAD - Sistem Informasi Manajemen Undangan" />
                      <Dashboard />
                    </>
                  }
                />
                <Route
                  path="/calendar"
                  element={
                    <>
                      <PageTitle title="Kalender | SIMAD - Sistem Informasi Manajemen Undangan" />
                      <Calendar />
                    </>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <>
                      <PageTitle title="Profil | SIMAD - Sistem Informasi Manajemen Undangan" />
                      <Profile />
                    </>
                  }
                />
                <Route
                  path="/forms/invitations"
                  element={
                    <>
                      <PageTitle title="Undangan | SIMAD - Sistem Informasi Manajemen Undangan" />
                      <InvitationsFormAdmin />
                    </>
                  }
                />
                <Route
                  path="/forms/invitations/:id"
                  element={
                    <>
                      <PageTitle title="Detail Undangan | SIMAD - Sistem Informasi Manajemen Undangan" />
                      <InvitationsShow />
                    </>
                  }
                />

                <Route
                  path="/forms/invitations/edit/:id"
                  element={
                    <>
                      <PageTitle title="Edit Undangan | SIMAD - Sistem Informasi Manajemen Undangan" />
                      <InvitationsEdit />
                    </>
                  }
                />
                <Route
                  path="/report"
                  element={
                    <>
                      <PageTitle title="Laporan | SIMAD - Sistem Informasi Manajemen Undangan" />
                      <Report />
                    </>
                  }
                />

                <Route
                  path="/opd"
                  element={
                    <>
                      <PageTitle title="Data Organisasi Perangkat Daerah | SIMAD - Sistem Informasi Manajemen Undangan" />
                      <Opd />
                    </>
                  }
                />

                <Route
                  path="/opd/:id"
                  element={
                    <>
                      <PageTitle title="Detail OPD | SIMAD - Sistem Informasi Manajemen Undangan" />
                      <OpdShow />
                    </>
                  }
                />

                <Route
                  path="/opd/edit/:id"
                  element={
                    <>
                      <PageTitle title="Edit Data OPD | SIMAD - Sistem Informasi Manajemen Undangan" />
                      <OpdEdit />
                    </>
                  }
                />
              </Routes>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
