import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import { MaterialReactTable } from "material-react-table";

//Import Material React Table Translations
import { MRT_Localization_ID } from "material-react-table/locales/id";

const InvitationsTable = () => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.simad.adagempa.cloud/api/invitations", {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error("Gagal mengambil data.");
        }

        const data = await response.json();
        const invitations = data.data.map((invitation) => ({
          ...invitation,

          tanggal: new Date(invitation.date).toLocaleDateString(),
          waktu: `${invitation.start_time} - ${invitation.end_time}`,
          pd_organizer: invitation.organizer_name, 
        }));
        setTableData(invitations);
      } catch (error) {
        console.error("Error fetching invitations:", error);
      }
    };

    fetchData();
  }, []);

  // Mendefinisikan kolom tabel
  const columns = useMemo(
    () => [
      {
        accessorKey: "nomor_undangan",
        header: "Nomor Undangan",
      },
      {
        accessorKey: "tanggal",
        header: "Tanggal",
      },
      {
        accessorKey: "waktu",
        header: "Waktu",
      },
      {
        accessorKey: "place",
        header: "Tempat",
      },
      {
        accessorKey: "agenda",
        header: "Agenda",
      },
      {
        accessorKey: "leader_name",
        header: "Pemimpin",
      },
      {
        accessorKey: "pd_organizer",
        header: "PD Pelaksana",
      },
      {
        accessorKey: "actions",
        header: "Keterangan",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded-full"
              onClick={() => handleView(row.original.invitation_id)}
            >
              Lihat
            </button>
            <button
              className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded-full"
              onClick={() => handleEdit(row.original.invitation_id)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full"
              onClick={() => handleDelete(row.original.invitation_id)}
            >
              Hapus
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleView = (id) => {
    console.log("Lihat undangan dengan ID:", id);
    window.location.href = `/forms/invitations/${id}`;
  };

  const handleEdit = (id) => {
    console.log("Edit undangan dengan ID:", id);
    window.location.href = `/forms/invitations/edit/${id}`;
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Anda tidak dapat mengembalikan ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Ya, hapus!",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(
          `https://api.simad.adagempa.cloud/api/invitations/${id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Gagal menghapus undangan.");
        }

        Swal.fire("Dihapus!", "Undangan berhasil dihapus.", "success");
        setTableData((prevData) =>
          prevData.filter((row) => row.invitation_id !== id)
        );
      } catch (error) {
        console.error("Error deleting invitation:", error);
        Swal.fire("Gagal!", "Gagal menghapus undangan.", "error");
      }
    }
  };

  return (
    <MaterialReactTable
      columns={columns}
      data={tableData}
      localization={MRT_Localization_ID}
      enableFullScreenToggle={false}
      initialState={{ density: "compact" }}
    />
  );
};

export default InvitationsTable;
