import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/dashboard/Breadcrumbs/Breadcrumb";
import Swal from "sweetalert2";

const OpdEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ name: "", role: "" });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  // Fetch data OPD ketika komponen di-mount
  useEffect(() => {
    const fetchPeopleDetails = async () => {
      try {
        const response = await fetch(`https://api.simad.adagempa.cloud/api/people/${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch people details");
        }
        const data = await response.json();

        // Cek apakah role adalah "Bupati" atau "Sekda"
        if (data.data.role === "Bupati" || data.data.role === "Sekda") {
          Swal.fire({
            icon: "warning",
            title: "Akses Ditolak",
            text: "Data OPD dengan role Bupati/Sekda tidak dapat diubah.",
            showConfirmButton: false,
            timer: 2000,
          });
          navigate("/opd"); // Redirect jika role tidak boleh di-edit
        } else {
          setFormData({ name: data.data.name, role: data.data.role });
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPeopleDetails();
  }, [id, navigate]);

  // Fungsi untuk menangani perubahan input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Fungsi untuk menangani submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`https://api.simad.adagempa.cloud/api/people/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json(); // Parsing responsenya menjadi JSON

      if (!response.ok || !result.success) {
        throw new Error(result.message || "Failed to update people data");
      }

      // Jika update berhasil, redirect ke halaman daftar OPD dan tampilkan notifikasi
      navigate("/opd");
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: result.message || "Data OPD berhasil diperbarui",
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <Breadcrumb pageName="Data OPD / Edit Data OPD" />
      <h2 className="text-2xl font-bold mb-4 mt-4">Edit Data OPD</h2>

      <div className="grid grid-cols-1 gap-9">
        <div className="flex flex-col gap-9">
          <div className="rounded-sm border border-stroke bg-white shadow-default">
            <div className="border-b border-stroke py-4 px-6.5">
              <h3 className="font-medium text-black">UBAH DATA OPD</h3>
            </div>
            <form
              onSubmit={handleSubmit}
              className="bg-white shadow-md rounded-lg p-6 sm:p-8 md:p-10"
            >
              {errorMessage && (
                <div className="mb-4 text-red-600">{errorMessage}</div>
              )}
              <div className="space-y-6">
                {/* Nama OPD */}
                <div className="col-span-full mb-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900 text-start"
                  >
                    Nama OPD
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="border rounded-md p-2 w-full"
                      required
                      autoComplete="off"
                    />
                  </div>
                </div>

                {/* Jabatan OPD */}
                <div className="col-span-full mb-4">
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium leading-6 text-gray-900 text-start"
                  >
                    Jabatan OPD
                  </label>
                  <div className="mt-2">
                    <select
                      id="role"
                      name="role"
                      onChange={handleChange}
                      className="border rounded-md p-2 w-full"
                      required
                    >
                      <option value="">Pilih Jabatan</option>
                      <option value="Kepala Dinas">KEPALA DINAS</option>
                      <option value="Camat">CAMAT</option>
                      <option value="Lurah">LURAH</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="mt-8">
                <button
                  type="submit"
                  className="w-full rounded bg-indigo-600 px-4 py-2 text-white font-medium hover:bg-indigo-700 text-center"
                  disabled={loading}
                >
                  {loading ? "Mengirim..." : "Perbarui Data OPD"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default OpdEdit;
