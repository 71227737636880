import React from "react";
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import DefaultLayout from "./components/dashboard/Layout/DefaultLayout";
import GuestRoute from "./components/dashboard/ProtectedRoute/GuestRoute";
import ProtectedRoute from "./components/dashboard/ProtectedRoute/ProtectedRoute";
import HeroHome from "./components/landing/hero-home";
import StepTimeline from "./components/landing/StepTimeline";
import Footer from "./components/landing/ui/footer";
import Header from "./components/landing/ui/header";
import Calendar from "./page/admin/Calendar";
import Dashboard from "./page/admin/Dashboard";
import InvitationsFormAdmin from "./page/admin/Form/InvitationsFormAdmin";
import InvitationsEdit from "./page/admin/invitations/InvitationsEdit";
import InvitationsShow from "./page/admin/invitations/InvitationsShow";
import Opd from "./page/admin/Opd";
import OpdEdit from "./page/admin/Opd/OpdEdit";
import OpdShow from "./page/admin/Opd/OpdShow";
import Profile from "./page/admin/Profile";
import Report from "./page/admin/Report";
import Login from "./page/auth/Login";
import MeetingForm from "./page/invitations/Meeting-Form";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Rute yang membutuhkan Header dan Footer */}
          <Route element={<LayoutWithHeaderFooter />}>
            <Route
              path="/"
              element={
                <>
                  <HeroHome />
                  <StepTimeline />
                  {/* <LargeTestimonial /> */}
                </>
              }
            />
            <Route path="/invitations" element={<MeetingForm people={[]} />} />
            {/* Rute yang hanya boleh diakses jika belum login */}
            <Route element={<GuestRoute />}>
              <Route path="/login" element={<Login />} />
            </Route>{" "}
          </Route>

          {/* Rute tanpa Header dan Footer, dan dengan proteksi */}
          <Route element={<ProtectedRoute />}>
            <Route element={<DefaultLayout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/profile" element={<Profile />} />
              <Route
                path="/forms/invitations"
                element={<InvitationsFormAdmin />}
              />
              <Route
                path="/forms/invitations/:id"
                element={<InvitationsShow />}
              />{" "}
              <Route
                path="/forms/invitations/edit/:id"
                element={<InvitationsEdit />}
              />
              {/* Rute untuk detail undangan */}
              <Route path="/report" element={<Report />} />
              <Route path="/opd" element={<Opd />} />
              <Route path="/opd/:id" element={<OpdShow />} />
              <Route path="/opd/edit/:id" element={<OpdEdit />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

const LayoutWithHeaderFooter = () => (
  <>
    <Header />
    <Outlet />
    <Footer />
  </>
);

export default App;
