import React from "react";
import Breadcrumb from "../../components/dashboard/Breadcrumbs/Breadcrumb";
import FullCalendar from "../../components/landing/full-calendar";

const Calendar = () => {
  return (
    <>
      <Breadcrumb pageName="Kalender" />
      <h2 className="text-2xl font-bold mt-4 mb-4">Kalender</h2>
      <FullCalendar />
    </>
  );
};

export default Calendar;
