import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

const OpdForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    role: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [opdList, setOpdList] = useState([]);

  // Handle Change: Merubah data menjadi uppercase
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.toUpperCase(),
    }));
  };

  useEffect(() => {
    // Fetch daftar OPD dari backend
    const fetchOpdList = async () => {
      try {
        const response = await fetch("https://api.simad.adagempa.cloud/api/roles");
        const result = await response.json();

        // Periksa apakah respons berhasil
        if (result.success) {
          const data = result.data; // Ambil data dari respons

          setOpdList(
            data.map((opd) => ({ value: opd.role_id, label: opd.role }))
          );
        } else {
          console.error("Error fetching people data:", result.message);
        }
      } catch (error) {
        console.error("Error fetching people data:", error);
      }
    };

    fetchOpdList();
  }, []);

  // Handle Submit: Proses form saat submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = `https://api.simad.adagempa.cloud/api/people`;

    setLoading(true);
    setErrorMessage(""); // Reset error message

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Include authorization
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json(); // Parsing JSON dari respons

      // Cek jika response gagal
      if (!response.ok || !result.success) {
        throw new Error(result.message || "Failed to save OPD data");
      }

      // Jika berhasil, tampilkan alert dan redirect
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Data OPD berhasil disimpan!",
        showConfirmButton: false,
        timer: 2000,
      });

      // TODO: Refresh page after submit
      // Refresh page or navigate after success
      setTimeout(() => {
        // Option 1: Refresh page
        window.location.reload();

        // Option 2: Navigate to another page
        // navigate("/some-other-page");
      }, 2000);
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message); // Tampilkan error message dari server
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h2 className="text-2xl font-bold mb-4 mt-4">Tambah Data OPD</h2>
      <div className="grid grid-cols-1 gap-9">
        <div className="flex flex-col gap-9">
          <div className="rounded-sm border border-stroke bg-white shadow-default">
            <div className="border-b border-stroke py-4 px-6.5">
              <h3 className="font-medium text-black">MASUKKAN DATA OPD</h3>
            </div>
            <form
              onSubmit={handleSubmit}
              className="bg-white shadow-md rounded-lg p-6 sm:p-8 md:p-10"
            >
              {errorMessage && (
                <div className="mb-4 text-red-600">{errorMessage}</div>
              )}

              <div className="space-y-6">
                {/* Nama OPD */}
                <div className="col-span-full mb-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900 text-start"
                  >
                    Nama OPD
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="border rounded-md p-2 w-full"
                      required
                      autoComplete="off"
                    />
                  </div>
                </div>

                {/* Jabatan OPD */}
                <div className="col-span-full mb-4">
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium leading-6 text-gray-900 text-start"
                  >
                    Jabatan OPD
                  </label>
                  <div className="mt-2">
                    <select
                      id="role"
                      name="role"
                      value={formData.role}
                      onChange={(e) =>
                        setFormData({ ...formData, role: e.target.value })
                      }
                      className="border rounded-md p-2 w-full"
                      required
                    >
                      <option value="">Pilih Jabatan</option>
                      {opdList.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Tombol Kirim */}
                <div className="mt-8">
                  <button
                    type="submit"
                    className="w-full rounded bg-indigo-600 px-4 py-2 text-white font-medium hover:bg-indigo-700 text-center"
                    disabled={loading}
                  >
                    {loading ? "Mengirim..." : "Kirim Data OPD"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default OpdForm;
