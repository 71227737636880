import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import Breadcrumb from "../../../components/dashboard/Breadcrumbs/Breadcrumb";
import InvitationsTable from "../invitations/InvitationsTable";
import MeetingForm from "../../invitations/Meeting-Form";

const InvitationsFormAdmin = () => {
  return (
    <>
      <Breadcrumb pageName="Undangan" />

      <h2 className="text-2xl font-bold mb-4 mt-4">Daftar Undangan</h2>

      <InvitationsTable />

      <h2 className="text-2xl font-bold mb-4 mt-8">Undangan Baru</h2>

      <MeetingForm showHeading={false} context="admin" />
    </>
  );
};

export default InvitationsFormAdmin;
