import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "lucide-react";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="fixed top-0 z-30 w-full bg-white/90 shadow-lg shadow-black/[0.03] backdrop-blur-sm">
      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        <div className="flex items-center justify-between py-4">
          {/* Site branding (Logo) */}
          <div className="flex items-center">
            <Link to="/">
              <img
                src="https://simad.adagempa.cloud/madiun-seal.svg"
                alt="Logo"
                className="h-8 w-6 md:h-10 md:w-8"
              />
            </Link>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-500 hover:text-gray-600"
            >
              <Menu size={24} />
            </button>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex items-center space-x-4">
            <Link
              to="/login"
              className="bg-gray-800 text-gray-200 hover:bg-gray-900 px-4 py-2 rounded-lg text-sm"
            >
              Login
            </Link>
          </nav>
        </div>

        {/* Mobile navigation */}
        {isMenuOpen && (
          <nav className="md:hidden py-4">
            <Link
              to="/login"
              className="btn block bg-gray-800 text-gray-200 hover:bg-gray-900 px-4 py-2 rounded-lg text-sm text-center w-full from-blue-600 to-blue-500"
            >
              Login
            </Link>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
