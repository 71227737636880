import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Breadcrumb from "../../components/dashboard/Breadcrumbs/Breadcrumb";
import ChartThree from "../../components/dashboard/Charts/ChartThree";
import ChartTwo from "../../components/dashboard/Charts/ChartTwo";

const Report = () => {
  const [selectedPD, setSelectedPD] = useState("");
  const [pdPelaksanaOptions, setPdPelaksanaOptions] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportType, setExportType] = useState("excel");

  useEffect(() => {
    // Mengambil data PD Pelaksana dari backend
    const fetchPdPelaksana = async () => {
      try {
        const response = await fetch("https://api.simad.adagempa.cloud/api/pd_pelaksana", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setPdPelaksanaOptions(data.data);
      } catch (error) {
        console.error("Error fetching PD Pelaksana:", error);
      }
    };

    fetchPdPelaksana();
  }, []);

  const formatDate = (date) => {
    if (!date) return "";
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  };

  const handleExport = async (endpoint) => {
    if (endpoint !== "daily" && (!startDate || !endDate)) {
      Swal.fire(
        "Peringatan",
        "Harap pilih rentang tanggal sebelum melakukan ekspor.",
        "warning"
      );
      return;
    }

    const queryParams = new URLSearchParams({
      type: exportType,
    });

    if (startDate && endpoint !== "daily")
      queryParams.append("startDate", startDate);
    if (endDate && endpoint !== "daily") queryParams.append("endDate", endDate);
    if (endpoint === "by-pd" && selectedPD) {
      queryParams.append("pdPelaksana", selectedPD);
    }

    const selectedPDName =
      pdPelaksanaOptions.find((pd) => {
        return String(pd.id) === String(selectedPD);
      })?.name || "PD Pelaksana tidak ditemukan";

    const confirmationMessage =
      endpoint === "daily"
        ? "Apakah Anda yakin ingin mengekspor data untuk hari ini?"
        : endpoint === "by-pd"
        ? `Apakah Anda yakin ingin mengekspor data untuk ${selectedPDName} dari tanggal ${formatDate(
            startDate
          )} hingga ${formatDate(endDate)}?`
        : `Apakah Anda yakin ingin mengekspor data dari tanggal ${formatDate(
            startDate
          )} hingga ${formatDate(endDate)}?`;

    const confirmation = await Swal.fire({
      title: "Konfirmasi Ekspor",
      text: confirmationMessage,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, Ekspor",
      cancelButtonText: "Batal",
    });

    if (!confirmation.isConfirmed) return;

    try {
      const url = `https://api.simad.adagempa.cloud/api/export/invitations/${endpoint}?${queryParams.toString()}`;

      if (exportType === "pdf") {
        const pdfUrl = `${url}&token=${localStorage.getItem("token")}`;
        window.open(pdfUrl, "_blank");
      } else {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          if (response.status === 404) {
            const errorData = await response.json();
            Swal.fire("Tidak Ada Data", errorData.message, "warning");
            return;
          }

          const blob = await response.blob();
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute(
            "download",
            `${endpoint}.${exportType === "excel" ? "xlsx" : "pdf"}`
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
        } catch (error) {
          console.error("Error during export:", error);
        }
      }
    } catch (error) {
      console.error("Error during export:", error);
    }
  };

  return (
    <>
      <Breadcrumb pageName="Laporan" />
      <div className="flex flex-col gap-10">
        <h2 className="text-2xl font-bold">Data Grafik Undangan</h2>

        <ChartTwo />

        <h2 className="text-2xl font-bold">Data Grafik Pengunjung</h2>

        <ChartThree />

        <h2 className="text-2xl font-bold">Ekspor Undangan</h2>

        {/* Input rentang tanggal */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="border rounded p-2"
          />
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="border rounded p-2"
          />
          <select
            value={exportType}
            onChange={(e) => setExportType(e.target.value)}
            className="border rounded p-2"
          >
            <option value="excel">Excel</option>
            <option value="pdf">PDF</option>
          </select>
        </div>

        {/* Dropdown untuk memilih PD Pelaksana */}
        <div className="mb-4">
          <select
            value={selectedPD}
            onChange={(e) => setSelectedPD(e.target.value)}
            className="border rounded p-2 w-full"
          >
            <option value="">Pilih PD Pelaksana</option>
            {pdPelaksanaOptions.map((pd) => (
              <option key={pd.id} value={pd.id}>
                {pd.name}
              </option>
            ))}
          </select>
        </div>

        {/* Tombol Ekspor */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <button
            onClick={() => handleExport("daily")}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Ekspor Harian
          </button>
          <button
            onClick={() => handleExport("by-date")}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Ekspor Rentang Tanggal
          </button>
          <button
            onClick={() => handleExport("by-pd")}
            className="bg-blue-500 text-white px-4 py-2 rounded"
            disabled={!selectedPD}
          >
            Ekspor Per PD Pelaksana
          </button>
        </div>
      </div>
    </>
  );
};

export default Report;
