import React, { useState } from "react";
import Swal from "sweetalert2";

const JabatanForm = () => {
  const [formData, setFormData] = useState({
    role: "", // Pastikan ini sesuai dengan parameter API
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.toUpperCase(),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = `https://api.simad.adagempa.cloud/api/roles`;

    setLoading(true);
    setErrorMessage("");

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (!response.ok || !result.success) {
        throw new Error(result.message || "Failed to save JABATAN data");
      }

      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Data JABATAN berhasil disimpan!",
        showConfirmButton: false,
        timer: 2000,
      });

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h2 className="text-2xl font-bold mb-4 mt-4">Tambah Data JABATAN</h2>
      <div className="grid grid-cols-1 gap-9">
        <div className="flex flex-col gap-9">
          <div className="rounded-sm border border-stroke bg-white shadow-default">
            <div className="border-b border-stroke py-4 px-6.5">
              <h3 className="font-medium text-black">MASUKKAN DATA JABATAN</h3>
            </div>
            <form
              onSubmit={handleSubmit}
              className="bg-white shadow-md rounded-lg p-6 sm:p-8 md:p-10"
            >
              {errorMessage && (
                <div className="mb-4 text-red-600">{errorMessage}</div>
              )}

              <div className="space-y-6">
                {/* Nama JABATAN */}
                <div className="col-span-full mb-4">
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium leading-6 text-gray-900 text-start"
                  >
                    Nama JABATAN
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="role"
                      name="role"
                      value={formData.role}
                      onChange={handleChange}
                      className="border rounded-md p-2 w-full"
                      required
                      autoComplete="off"
                    />
                  </div>
                </div>

                {/* Tombol Kirim */}
                <div className="mt-8">
                  <button
                    type="submit"
                    className="w-full rounded bg-indigo-600 px-4 py-2 text-white font-medium hover:bg-indigo-700 text-center"
                    disabled={loading}
                  >
                    {loading ? "Mengirim..." : "Kirim Data JABATAN"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default JabatanForm;
