import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Swal from "sweetalert2";

const ProtectedRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await fetch(
          "https://api.simad.adagempa.cloud/api/protected-route",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json(); // Ambil data dari respons

        // Periksa apakah respons memiliki success = true
        if (data.success) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    checkAuth();

    // Tampilkan SweetAlert saat pengecekan autentikasi
    Swal.fire({
      title: "Memeriksa Autentikasi...",
      text: "Mohon tunggu sebentar",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }, []);

  useEffect(() => {
    // Tutup SweetAlert setelah pengecekan autentikasi selesai
    if (isAuthenticated !== null) {
      Swal.close();
    }
  }, [isAuthenticated]);

  // Jika belum selesai pengecekan, tampilkan loading
  if (isAuthenticated === null) {
    return null; // Tidak menampilkan apapun karena sudah ada SweetAlert
  }

  // Jika tidak terautentikasi, arahkan ke halaman login
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Jika terautentikasi, tampilkan halaman yang dilindungi
  return <Outlet />;
};

export default ProtectedRoute;
