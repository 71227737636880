import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Swal from "sweetalert2";

const GuestRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setIsAuthenticated(false);
        return;
      }

      try {
        const response = await fetch(
          "https://api.simad.adagempa.cloud/api/protected-route",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json(); // Ambil data dari respons

        // Periksa apakah respons memiliki success = true
        if (data.success) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    checkAuth();

    // Tampilkan SweetAlert saat memeriksa autentikasi
    Swal.fire({
      title: "Sedang Memeriksa...",
      text: "Mohon tunggu sebentar",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }, []);

  useEffect(() => {
    // Tutup SweetAlert setelah autentikasi diperiksa
    if (isAuthenticated !== null) {
      Swal.close();
    }
  }, [isAuthenticated]);

  // Jika sudah terautentikasi, arahkan ke dashboard
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  // Jika belum terautentikasi, biarkan akses ke halaman login
  return <Outlet />;
};

export default GuestRoute;
