import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_ID } from "material-react-table/locales/id";
import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import Breadcrumb from "../../components/dashboard/Breadcrumbs/Breadcrumb";
import OpdForm from "./Form/OpdForm";
import JabatanForm from "./Form/JabatanForm";

const Opd = () => {
  const [tableData, setTableData] = useState([]);
  const [rolesData, setRolesData] = useState([]);

  // Fetch Data People
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.simad.adagempa.cloud/api/people", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Gagal mengambil data.");
        }

        const data = await response.json();
        setTableData(data.data);
      } catch (error) {
        console.error("Error fetching people:", error);
      }
    };
    fetchData();
  }, []);

  // Fetch Data Roles
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch("https://api.simad.adagempa.cloud/api/roles", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Gagal mengambil data roles.");
        }

        const data = await response.json();
        setRolesData(data.data);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);

  // Definisi Kolom Tabel People
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "PEJABAT",
      },
      {
        accessorKey: "role_name",
        header: "JABATAN",
      },
      {
        accessorKey: "actions",
        header: "AKSI",
        Cell: ({ row }) => {
          const protectedRoles =
            /BUPATI|SEKRETARIS DAERAH|PJ BUPATI|PJ SEKRETARIS DAERAH|ASISTEN/i;

          return (
            <div className="flex space-x-2">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded-full"
                onClick={() => handleViewOpd(row.original.id)}
              >
                Lihat
              </button>

              {!protectedRoles.test(row.original.role_name) && (
                <>
                  <button
                    className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded-full"
                    onClick={() => handleEditOpd(row.original.id)}
                  >
                    Edit
                  </button>
                  <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full"
                    onClick={() => handleDeleteOpd(row.original.id)}
                  >
                    Hapus
                  </button>
                </>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  // Definisi Kolom Tabel Roles
  const rolesColumns = useMemo(
    () => [
      {
        accessorKey: "role",
        header: "NAMA JABATAN",
      },
      {
        accessorKey: "actions",
        header: "AKSI",
        Cell: ({ row }) => {
          return (
            <div className="flex space-x-2">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded-full"
                onClick={() => handleViewRole(row.original.id)}
              >
                Lihat
              </button>

              <button
                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded-full"
                onClick={() => handleEditRole(row.original.id)}
              >
                Edit
              </button>
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full"
                onClick={() => handleDeleteRole(row.original.id)}
              >
                Hapus
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  const handleViewOpd = (id) => {
    console.log("Lihat OPD dengan ID:", id);
    window.location.href = `/opd/${id}`;
  };

  const handleViewRole = (id) => {
    console.log("Lihat Jabatan dengan ID:", id);
    window.location.href = `/jabatan/${id}`;
  };

  const handleEditOpd = (id) => {
    console.log("Edit OPD dengan ID:", id);
    window.location.href = `/opd/edit/${id}`;
  };

  const handleEditRole = (id) => {
    console.log("Edit Jabatan dengan ID:", id);
    window.location.href = `/jabatan/edit/${id}`;
  };

  const handleDeleteOpd = async (id) => {
    const result = await Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Anda tidak dapat mengembalikan ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Ya, hapus!",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(`https://api.simad.adagempa.cloud/api/people/${id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error("Gagal menghapus OPD.");
        }

        Swal.fire("Dihapus!", "Data OPD berhasil dihapus.", "success");
        setTableData((prevData) => prevData.filter((row) => row.id !== id));
      } catch (error) {
        console.error("Error deleting OPD:", error);
        Swal.fire("Gagal!", "Gagal menghapus OPD.", "error");
      }
    }
  };

  const handleDeleteRole = async (id) => {
    const result = await Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Menghapus data jabatan akan menghapus data OPD yang terkait.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Ya, hapus!",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(`https://api.simad.adagempa.cloud/api/roles/${id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (!response.ok) {
          throw new Error("Gagal menghapus Jabatan.");
        }
        Swal.fire("Dihapus!", "Data Jabatan berhasil dihapus.", "success");
        setRolesData((prevData) => prevData.filter((row) => row.id !== id));
      } catch (error) {
        console.error("Error deleting Jabatan:", error);
        Swal.fire("Gagal!", "Gagal menghapus Jabatan.", "error");
      }
    }
  };

  return (
    <>
      <Breadcrumb pageName="Data OPD" />
      <h2 className="text-2xl font-bold mt-4 mb-4">
        Data Organisasi Perangkat Daerah
      </h2>
      <MaterialReactTable
        columns={columns}
        data={tableData}
        localization={MRT_Localization_ID}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        initialState={{
          density: "compact",
          // pagination: {
          //   pageSize: 100, // Jumlah baris per halaman
          // },
        }}
      />

      <OpdForm />

      <h2 className="text-2xl font-bold mt-8 mb-4">Data JABATAN</h2>

      <MaterialReactTable
        columns={rolesColumns}
        data={rolesData}
        localization={MRT_Localization_ID}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        initialState={{ density: "compact" }}
      />

      <JabatanForm />
    </>
  );
};

export default Opd;
