import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/dashboard/Breadcrumbs/Breadcrumb";

const InvitationsEdit = () => {
  const { id } = useParams(); // Ambil ID dari URL
  const [formData, setFormData] = useState({
    nomorUndangan: "",
    tanggal: "",
    waktuMulai: "",
    waktuSelesai: "",
    tempat: "",
    agenda: "",
    pdPelaksana: "",
    pemimpinRapat: "",
    peserta: [],
    fileUndangan: null, // Menambahkan state untuk file undangan
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [pemimpinOptions, setPemimpinOptions] = useState([]);
  const [pesertaOptions, setPesertaOptions] = useState([]);

  useEffect(() => {
    // Fetch people data from API
    const fetchPeople = async () => {
      try {
        const response = await fetch("https://api.simad.adagempa.cloud/api/people");
        const result = await response.json();

        // Periksa apakah respons berhasil
        if (result.success) {
          const data = result.data; // Ambil data dari respons

          // Filter based on role
          const pemimpin = data.filter((person) =>
            /Bupati|Sekda/i.test(person.role)
          );
          const peserta = data.filter((person) =>
            /Camat|Lurah|Kepala Dinas/i.test(person.role)
          );

          // Map to Select component format
          setPemimpinOptions(
            pemimpin.map((person) => ({ value: person.id, label: person.name }))
          );
          setPesertaOptions(
            peserta.map((person) => ({ value: person.id, label: person.name }))
          );
        } else {
          console.error("Error fetching people data:", result.message);
        }
      } catch (error) {
        console.error("Error fetching people data:", error);
      }
    };

    fetchPeople();
  }, []);

  useEffect(() => {
    // Fetch undangan by ID dari API dan set ke state formData
    const fetchInvitation = async () => {
      try {
        const response = await fetch(
          `https://api.simad.adagempa.cloud/api/invitations/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const result = await response.json();

        if (result.success) {
          const data = result.data;

          // Gabungkan tanggal dan waktu untuk membuat format yang bisa di-parse oleh Date()
          const parseDateTime = (date, time) => {
            return new Date(`${date}T${time}`);
          };

          setFormData({
            nomorUndangan: data.invitation.nomor_undangan,
            tanggal: new Date(data.invitation.tanggal), // Gunakan tanggal yang sudah dalam format ISO
            waktuMulai: parseDateTime(
              data.invitation.tanggal,
              data.invitation.waktu_mulai
            ),
            waktuSelesai: parseDateTime(
              data.invitation.tanggal,
              data.invitation.waktu_selesai
            ),
            tempat: data.invitation.tempat,
            agenda: data.invitation.agenda,
            pdPelaksana: data.invitation.pd_pelaksana,
            pemimpinRapat: data.invitation.pemimpin_id,
            peserta: data.participants.map((p) => ({
              value: p.id,
              label: p.name,
            })),
            fileUndangan: null, // File tidak di-fetch dari server
          });
        } else {
          setErrorMessage(result.message || "Gagal memuat data undangan");
        }
      } catch (error) {
        setErrorMessage("Gagal memuat data undangan");
      }
    };

    fetchInvitation();
  }, [id]);

  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, fileUndangan: e.target.files[0] }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date, field) => {
    setFormData((prev) => ({ ...prev, [field]: date }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    data.append("nomorUndangan", formData.nomorUndangan);
    data.append("tanggal", formData.tanggal.toISOString().split("T")[0]);
    data.append("waktuMulai", formData.waktuMulai);
    data.append("waktuSelesai", formData.waktuSelesai);
    data.append("tempat", formData.tempat);
    data.append("agenda", formData.agenda);
    data.append("pdPelaksana", formData.pdPelaksana);
    data.append("pemimpinRapat", formData.pemimpinRapat);
    data.append(
      "peserta",
      JSON.stringify(formData.peserta.map((p) => p.value))
    );
    if (formData.fileUndangan) {
      data.append("fileUndangan", formData.fileUndangan);
    }

    try {
      const response = await fetch(
        `https://api.simad.adagempa.cloud/api/invitations/${id}`,
        {
          method: "PUT",
          body: data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Gagal mengupdate undangan ");
      }

      // Redirect ke halaman daftar undangan
      window.location.href = "/invitations";
    } catch (error) {
      setErrorMessage("Gagal menyimpan perubahan");
    } finally {
      setLoading(false);
    }
  };

  const inputClassName =
    "block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6";

  // Custom styles for react-datepicker
  const datePickerWrapperClassName = "react-datepicker-wrapper w-full";
  const datePickerClassName = "react-datepicker__input-container";
  const datePickerInputClassName = `${inputClassName} w-full`;

  return (
    <>
      <Breadcrumb pageName="Undangan" />

      <h2 className="text-2xl font-bold mb-4 mt-4">Edit Undangan</h2>

      <div className="grid grid-cols-1 gap-9">
        <div className="flex flex-col gap-9">
          {/* <!-- Contact Form --> */}
          <div className="rounded-sm border border-stroke bg-white shadow-default">
            <div className="border-b border-stroke py-4 px-6.5">
              <h3 className="font-medium text-black">MASUKKAN JADWAL RAPAT</h3>
            </div>
            <form
              onSubmit={handleSubmit}
              className="bg-white shadow-md rounded-lg p-6 sm:p-8 md:p-10"
            >
              {errorMessage && (
                <div className="mb-4 text-red-600">{errorMessage}</div>
              )}
              <div className="space-y-6">
                <div className="col-span-full mb-4">
                  <label
                    htmlFor="nomorUndangan"
                    className="block text-sm font-medium leading-6 text-gray-900 text-start"
                  >
                    Nomor Undangan
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="nomorUndangan"
                      name="nomorUndangan"
                      value={formData.nomorUndangan}
                      onChange={handleInputChange}
                      className={inputClassName}
                      required
                      autoComplete="off"
                      style={{ textTransform: "uppercase" }}
                    />
                  </div>
                </div>

                <div className="col-span-full mb-4">
                  <label
                    htmlFor="tanggal"
                    className="block text-sm font-medium leading-6 text-gray-900 text-start"
                  >
                    Tanggal
                  </label>
                  <div className="mt-2">
                    <DatePicker
                      selected={formData.tanggal}
                      onChange={(date) => handleDateChange(date, "tanggal")}
                      dateFormat="dd/MM/yyyy"
                      wrapperClassName={datePickerWrapperClassName}
                      className={datePickerInputClassName}
                      calendarClassName={datePickerClassName}
                      required
                    />
                  </div>
                </div>

                <div className="col-span-full mb-4">
                  <label
                    htmlFor="waktuMulai"
                    className="block text-sm font-medium leading-6 text-gray-900 text-start"
                  >
                    Waktu Mulai
                  </label>
                  <div className="mt-2">
                    <DatePicker
                      selected={formData.waktuMulai}
                      onChange={(date) => handleDateChange(date, "waktuMulai")}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      wrapperClassName={datePickerWrapperClassName}
                      className={datePickerInputClassName}
                      calendarClassName={datePickerClassName}
                      required
                    />
                  </div>
                </div>

                <div className="col-span-full mb-4">
                  <label
                    htmlFor="waktuSelesai"
                    className="block text-sm font-medium leading-6 text-gray-900 text-start"
                  >
                    Waktu Selesai
                  </label>
                  <div className="mt-2">
                    <DatePicker
                      selected={formData.waktuSelesai}
                      onChange={(date) =>
                        handleDateChange(date, "waktuSelesai")
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      wrapperClassName={datePickerWrapperClassName}
                      className={datePickerInputClassName}
                      calendarClassName={datePickerClassName}
                      required
                    />
                  </div>
                </div>

                <div className="col-span-full mb-4">
                  <label
                    htmlFor="tempat"
                    className="block text-sm font-medium leading-6 text-gray-900 text-start"
                  >
                    Tempat
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="tempat"
                      name="tempat"
                      value={formData.tempat}
                      onChange={handleInputChange}
                      required
                      autoComplete="off"
                      className={inputClassName}
                      style={{ textTransform: "uppercase" }} // Menambahkan style untuk uppercase
                    />
                  </div>
                </div>

                <div className="col-span-full mb-4">
                  <label
                    htmlFor="agenda"
                    className="block text-sm font-medium leading-6 text-gray-900 text-start"
                  >
                    Agenda
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="agenda"
                      name="agenda"
                      rows={3}
                      value={formData.agenda}
                      onChange={handleInputChange}
                      required
                      autoComplete="off"
                      className={inputClassName}
                      style={{ textTransform: "uppercase" }} // Menambahkan style untuk uppercase
                    />
                  </div>
                </div>

                <div className="col-span-full mb-4">
                  <label
                    htmlFor="pdPelaksana"
                    className="block text-sm font-medium leading-6 text-gray-900 text-start"
                  >
                    PD Pelaksana Undangan
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="pdPelaksana"
                      name="pdPelaksana"
                      value={formData.pdPelaksana}
                      onChange={handleInputChange}
                      required
                      autoComplete="off"
                      className={inputClassName}
                      style={{ textTransform: "uppercase" }} // Menambahkan style untuk uppercase
                    />
                  </div>
                </div>

                <div className="col-span-full mb-4">
                  <label
                    htmlFor="pemimpinRapat"
                    className="block text-sm font-medium leading-6 text-gray-900 text-start"
                  >
                    Pemimpin Rapat
                  </label>
                  <div className="mt-2">
                    <select
                      id="pemimpinRapat"
                      name="pemimpinRapat"
                      value={formData.pemimpinRapat}
                      onChange={handleInputChange}
                      required
                      autoComplete="off"
                      className={inputClassName}
                    >
                      <option value="">Pilih Pemimpin</option>
                      {pemimpinOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-span-full mb-4">
                  <label
                    htmlFor="peserta"
                    className="block text-sm font-medium leading-6 text-gray-900 text-start"
                  >
                    Peserta Rapat
                  </label>
                  <div className="mt-2">
                    <Select
                      isMulti
                      options={pesertaOptions}
                      value={formData.peserta}
                      onChange={(selectedOptions) =>
                        setFormData((prev) => ({
                          ...prev,
                          peserta: selectedOptions,
                        }))
                      }
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                </div>

                <div className="col-span-full mb-4">
                  <label
                    htmlFor="fileUndangan"
                    className="block text-sm font-medium leading-6 text-gray-900 text-start"
                  >
                    File Dokumen Undangan
                  </label>
                  <div className="mt-2">
                    <input
                      type="file"
                      id="fileUndangan"
                      name="fileUndangan"
                      onChange={handleFileChange}
                      className={inputClassName}
                      accept=".pdf,.doc,.docx"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="mt-8">
                <button
                  type="submit"
                  className="w-full rounded bg-indigo-600 px-4 py-2 text-white font-medium hover:bg-indigo-700 text-center"
                  disabled={loading}
                >
                  {loading ? "Mengirim..." : "Kirim Undangan"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvitationsEdit;
