import Breadcrumb from "../../components/dashboard/Breadcrumbs/Breadcrumb";

const Profile = () => {
  return (
    <>
      <Breadcrumb pageName="Profile" />

      <div className="overflow-hidden rounded-sm border border-stroke bg-white shadow-default">
        <div className="relative z-20 h-35 md:h-65">
          <img
            src="https://mascargoexpress.com/wp-content/uploads/2019/09/pendopo-700x300.jpg"
            alt="profile cover"
            className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
          />
        </div>
        <div className="px-4 pb-6 text-center lg:pb-8 xl:pb-11.5">
          <div className="relative z-30 mx-auto -mt-22 h-30 w-full max-w-30 rounded-full bg-white/20 p-1 backdrop-blur sm:h-44 sm:max-w-44 sm:p-3">
            <div className="relative drop-shadow-2">
              <img
                src="https://pemajuankebudayaan.id/wp-content/uploads/2022/05/Seal_of_Madiun_Regency.svg_.png"
                alt="profile"
              />
            </div>
          </div>
          <div className="mt-4">
            <h3 className="mb-1.5 text-2xl font-semibold text-black">SIMAD</h3>
            <p className="font-medium">
              SISTEM INFORMASI MANAJEMEN UNDANGAN KABUPATEN MADIUN
            </p>
            <div className="mx-auto max-w-180">
              <h4 className="font-semibold text-black">Tentang Aplikasi</h4>
              <p className="mt-4.5">
                Sistem Informasi Manajemen Undangan Madiun dirancang secara
                khusus untuk membantu Pemerintah Kabupaten Madiun dalam
                mengelola dan mengoordinasikan jadwal rapat dengan lebih efisien
                dan terstruktur. Sistem ini memungkinkan berbagai instansi
                pemerintah di Kabupaten Madiun untuk merencanakan,
                menyebarluaskan, dan melacak agenda rapat secara real-time,
                serta mempermudah pemangku kepentingan dalam mengakses undangan
                dan dokumen terkait.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
