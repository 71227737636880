import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import EventIcon from "@mui/icons-material/Event";
import SendIcon from "@mui/icons-material/Send";
import DoneIcon from "@mui/icons-material/Done";
import Typography from "@mui/material/Typography";

export default function CustomizedTimeline() {
  return (
    <>
      <h4
        className="mb-6 border-y text-4xl font-bold [border-image:linear-gradient(to_right,transparent,theme(colors.slate.300/.8),transparent)1] md:text-5xl text-center"
        data-aos="zoom-y-out"
        data-aos-delay="150"
        id="step-timeline"
      >
        Panduan Pengisian Sistem Informasi Manajemen Undangan
      </h4>
      <Timeline position="alternate">
        {/* Step 1: Buat Undangan */}
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            Langkah Pertama
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
              <EventIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              Melihat Ketersediaan Waktu Pada Kalender
            </Typography>
            <Typography>
              Pastikan untuk mengecek ketersediaan jadwal Bupati/PJ Bupati,
              Sekretaris Daerah/PJ Sekretaris Daerah, dan/atau Asisten pada kalender
            </Typography>
          </TimelineContent>
        </TimelineItem>

        {/* Step 2: Kirim Undangan */}
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            variant="body2"
            color="text.secondary"
          >
            Langkah Kedua
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <SendIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              Mengisi Formulir Undangan
            </Typography>
            <Typography>
              Sesudah melihat ketersediaan jadwal, silakan mengisi formulir
              undangan pada halaman data undangan. Jika terjadi bentrok
              waktu/jadwal, maka undangan tidak dapat dikirimkan
            </Typography>
          </TimelineContent>
        </TimelineItem>

        {/* Step 3: Konfirmasi Kehadiran */}
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            Langkah Ketiga
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary" variant="outlined">
              <DoneIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              Catat Jadwal dan Mulai Rapat
            </Typography>
            <Typography>
              Pastikan untuk mencatat jadwal yang sudah dimasukkan kedalam
              kalender. Pastikan untuk tidak lupa menghadiri rapat pada jadwal
              yang telah dibuat
            </Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </>
  );
}
