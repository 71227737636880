import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/dashboard/Breadcrumbs/Breadcrumb";

const OpdShow = () => {
  const { id } = useParams();
  const [peopleData, setpeopleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchpeopleDetails = async () => {
      try {
        const response = await fetch(`https://api.simad.adagempa.cloud/api/people/${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch people details");
        }
        const data = await response.json();
        setpeopleData(data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchpeopleDetails();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!peopleData) return <p>No people data available</p>;

  const people = peopleData;

  return (
    <>
      <Breadcrumb pageName="Data OPD / Detail Data OPD" />

      <h2 className="text-2xl font-bold mb-4 mt-4">Detail Data OPD</h2>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="w-full bg-gray-100 border-b border-gray-200">
              <th className="text-left py-2 px-4">Detail</th>
              <th className="text-left py-2 px-4">Informasi</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-gray-200">
              <td className="py-2 px-4 font-medium">Nama</td>
              <td className="py-2 px-4">{people.name}</td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="py-2 px-4 font-medium">Jabatan</td>
              <td className="py-2 px-4">{people.role_name}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default OpdShow;
