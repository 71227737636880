import idLocale from "@fullcalendar/core/locales/id";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const FullCalendarComponent = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchInvitations = async () => {
      try {
        const response = await fetch("https://api.simad.adagempa.cloud/api/invitations");
        const result = await response.json();

        if (result.success && result.data) {
          const formattedEvents = result.data.map((invitation) => {
            return {
              title: `Undangan: ${invitation.leader_name}`,
              start: `${invitation.date}T${invitation.start_time}`,
              end: `${invitation.date}T${invitation.end_time}`,
              extendedProps: {
                invitation_id: invitation.invitation_id,
                leader_name: invitation.leader_name,
                participants: invitation.participants,
                documents: invitation.documents,
                agenda: invitation.agenda,
                organizer_name: invitation.organizer_name,
                place: invitation.place,
                nomor_undangan: invitation.nomor_undangan,
              },
            };
          });

          setEvents(formattedEvents);
        } else {
          console.error("Error fetching invitations:", result.message);
        }
      } catch (error) {
        console.error("Error fetching invitations:", error);
      }
    };

    fetchInvitations();
  }, []);

  const handleEventClick = (info) => {
    setSelectedEvent(info.event);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
  };

  return (
    <div className="bg-white text-black rounded-lg shadow-inner p-4">
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
        initialView="dayGridMonth"
        locale={idLocale}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,listMonth",
        }}
        events={events}
        eventClick={handleEventClick}
      />

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Detail Undangan
          </Typography>
          {selectedEvent && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Nomor Undangan:</strong>{" "}
                  {selectedEvent.extendedProps.nomor_undangan}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <strong>Pemimpin:</strong>{" "}
                  {selectedEvent.extendedProps.leader_name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <strong>PD Pelaksana:</strong>{" "}
                  {selectedEvent.extendedProps.organizer_name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <strong>Tempat:</strong> {selectedEvent.extendedProps.place}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <strong>Agenda:</strong> {selectedEvent.extendedProps.agenda}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Waktu Mulai:</strong>{" "}
                  {selectedEvent.start.toLocaleString("id-ID")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Waktu Selesai:</strong>{" "}
                  {selectedEvent.end.toLocaleString("id-ID")}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  Peserta Undangan:
                </Typography>
                <ul>
                  {selectedEvent.extendedProps.participants.map(
                    (participant) => (
                      <li key={participant.id}>
                        {participant.name} - {participant.role}
                      </li>
                    )
                  )}
                </ul>
              </Grid>

              {selectedEvent.extendedProps.documents.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ mt: 2 }}>
                    Dokumen Terkait:
                  </Typography>
                  <ul>
                    {selectedEvent.extendedProps.documents.map((doc) => (
                      <li key={doc.id}>
                        <a
                          href={doc.file_path}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Lihat Dokumen
                        </a>
                      </li>
                    ))}
                  </ul>
                </Grid>
              )}
            </Grid>
          )}
          <Button onClick={handleCloseModal} variant="contained" sx={{ mt: 2 }}>
            Tutup
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default FullCalendarComponent;
