import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/dashboard/Breadcrumbs/Breadcrumb";

const InvitationsShow = () => {
  const { id } = useParams();
  const [invitationData, setInvitationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInvitationDetails = async () => {
      try {
        const response = await fetch(
          `https://api.simad.adagempa.cloud/api/invitations/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch invitation details");
        }
        const data = await response.json();
        setInvitationData(data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInvitationDetails();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!invitationData) return <p>No invitation data available</p>;

  const { invitation, participants, documents } = invitationData;

  return (
    <>
      <Breadcrumb pageName="Undangan / Detail Undangan" />

      <h2 className="text-2xl font-bold mb-4 mt-4">Detail Undangan</h2>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="w-full bg-gray-100 border-b border-gray-200">
              <th className="text-left py-2 px-4">Detail</th>
              <th className="text-left py-2 px-4">Informasi</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-gray-200">
              <td className="py-2 px-4 font-medium">Nomor Undangan</td>
              <td className="py-2 px-4">{invitation.nomor_undangan}</td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="py-2 px-4 font-medium">Tanggal</td>
              <td className="py-2 px-4">
                {new Date(invitation.tanggal).toLocaleDateString()}
              </td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="py-2 px-4 font-medium">Waktu Mulai</td>
              <td className="py-2 px-4">{invitation.waktu_mulai}</td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="py-2 px-4 font-medium">Waktu Selesai</td>
              <td className="py-2 px-4">{invitation.waktu_selesai}</td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="py-2 px-4 font-medium">Tempat</td>
              <td className="py-2 px-4">{invitation.tempat}</td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="py-2 px-4 font-medium">Agenda</td>
              <td className="py-2 px-4">{invitation.agenda}</td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="py-2 px-4 font-medium">PD Pelaksana Undangan</td>
              <td className="py-2 px-4">{invitation.organizer_name}</td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="py-2 px-4 font-medium">Pemimpin Rapat</td>
              <td className="py-2 px-4">{invitation.leader_name}</td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="py-2 px-4 font-medium">Peserta Rapat</td>
              <td className="py-2 px-4">
                {participants
                  .map((participants) => participants.participant_name)
                  .join(", ")}
              </td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="py-2 px-4 font-medium">Dokumen</td>
              <td className="py-2 px-4">
                {documents.length > 0 ? (
                  documents.map((doc) => (
                    <div key={doc.id}>
                      <a
                        href={doc.file_path}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {doc.file_path.split("/").pop()}{" "}
                      </a>
                    </div>
                  ))
                ) : (
                  <p>Tidak ada dokumen tersedia</p>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InvitationsShow;
