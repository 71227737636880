import React from "react";
import FullCalendarComponent from "./full-calendar"; // Ensure this path is correct

const HeroSection = () => {
  return (
    <section className="relative">
      {/* Page Illustration */}
      {/* Add any page illustration or other components here */}

      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        {/* Hero content */}
        <div className="pb-12 pt-32 md:pb-20 md:pt-40">
          {/* Section header */}
          <div className="pb-12 text-center md:pb-16">
            <h1
              className="mb-6 border-y text-5xl font-bold [border-image:linear-gradient(to_right,transparent,theme(colors.slate.300/.8),transparent)1] md:text-6xl"
              data-aos="zoom-y-out"
              data-aos-delay="150"
            >
              Sistem Informasi Manajemen Undangan
            </h1>
            <div className="mx-auto max-w-3xl">
              <p
                className="mb-8 text-lg text-gray-700"
                data-aos="zoom-y-out"
                data-aos-delay="300"
              >
                Platform terintegrasi untuk mengelola jadwal rapat dengan
                efisien. Didesain untuk membantu tim tetap terorganisir dan
                fokus pada tujuan, bukan hanya pada logistik rapat.
              </p>
              <p
                className="mb-8 text-lg text-gray-700 font-bold"
                data-aos="zoom-y-out"
                data-aos-delay="300"
              >
                APLIKASI INI MASIH DALAM TAHAP PENGEMBANGAN
              </p>
              <div className="relative before:absolute before:inset-0 before:border-y before:[border-image:linear-gradient(to_right,transparent,theme(colors.slate.300/.8),transparent)1]">
                <div
                  className="mx-auto max-w-xs sm:flex sm:max-w-none sm:justify-center"
                  data-aos="zoom-y-out"
                  data-aos-delay="450"
                >
                  <a
                    className="btn group mb-4 w-full bg-gradient-to-t from-blue-600 to-blue-500 bg-[length:100%_100%] bg-[bottom] text-white shadow hover:bg-[length:100%_150%] sm:mb-0 sm:w-auto rounded-full"
                    href="/invitations"
                  >
                    <span className="relative inline-flex items-center px-4 py-2">
                      Masukkan Undangan
                      <span className="ml-2 tracking-normal text-blue-300 transition-transform group-hover:translate-x-0.5">
                        -&gt;
                      </span>
                    </span>
                  </a>
                  {/* <a
                    className="btn w-full bg-white text-gray-800 shadow hover:bg-gray-50 sm:ml-4 sm:w-auto rounded-full"
                    href="#step-timeline"
                  >
                    <span className="relative inline-flex items-center px-4 py-2">
                      Pelajari Lebih Lanjut
                    </span>
                  </a> */}
                </div>
              </div>
            </div>
          </div>

          {/* FullCalendar Component */}
          <div
            className="mx-auto max-w-5xl"
            data-aos="zoom-y-out"
            data-aos-delay="600"
          >
            <div className="relative aspect-w-16 aspect-h-9 rounded-2xl bg-gray-900 px-5 py-3 shadow-xl before:pointer-events-none before:absolute before:-inset-5 before:border-y before:[border-image:linear-gradient(to_right,transparent,theme(colors.slate.300/.8),transparent)1] after:absolute after:-inset-5 after:-z-10 after:border-x after:[border-image:linear-gradient(to_bottom,transparent,theme(colors.slate.300/.8),transparent)1]">
              <div className="relative mb-8 flex items-center justify-between before:block before:h-[9px] before:w-[41px] before:bg-[length:16px_9px] before:[background-image:radial-gradient(circle_at_4.5px_4.5px,_theme(colors.gray.600)_4.5px,_transparent_0)] after:w-[41px]">
                <span className="text-lg font-medium text-white">
                  Kalender Jadwal Rapat
                </span>
              </div>
              <FullCalendarComponent />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
