import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Mendapatkan tahun saat ini

  return (
    <footer className="w-full py-14">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div className="flex space-x-10 justify-center items-center mb-3">
            <img
              className="rounded-full"
              src="https://simad.adagempa.cloud/madiun-seal.svg"
              width="48"
              height="48"
              alt="Large testimonial"
            />
          </div>
          <span className="text-lg text-gray-700 text-center block">
            © {currentYear}{" "}
            <a href="https://diskominfo.madiunkab.go.id/">
              Dinas Komunikasi dan Informatika Kabupaten Madiun
            </a>{" "}
            - All rights reserved
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;