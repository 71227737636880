import React, { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";

const MeetingForm = ({ showHeading = true, context = "landing" }) => {
  const wrapperClass =
    context === "admin"
      ? "w-full max-w-6xl bg-gray-50 p-4 rounded-lg shadow-md"
      : "w-full max-w-4xl bg-white p-8 rounded-lg shadow-lg";

  const [formData, setFormData] = useState({
    nomorUndangan: "",
    tanggal: "",
    waktuMulai: "",
    waktuSelesai: "",
    tempat: "",
    agenda: "",
    pdPelaksana: "",
    pemimpinRapat: "",
    peserta: [],
    fileUndangan: null,
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [pemimpinOptions, setPemimpinOptions] = useState([]);
  const [pdPelaksanaOptions, setPdPelaksanaOptions] = useState([]);
  const [pesertaOptions, setPesertaOptions] = useState([]);

  useEffect(() => {
    // Fetch people data from API
    const fetchPeople = async () => {
      try {
        const response = await fetch("https://api.simad.adagempa.cloud/api/people");
        const result = await response.json();

        // Periksa apakah respons berhasil
        if (result.success) {
          const data = result.data; // Ambil data dari respons

          // Tentukan role yang masuk kategori pemimpin
          const pemimpinRoles =
            /Bupati|Sekretaris Daerah|PJ Bupati|PJ Sekretaris Daerah|Asisten/i;

          // Filter based on role
          const pemimpin = data.filter((person) =>
            pemimpinRoles.test(person.role_name)
          );
          const peserta = data.filter(
            (person) => !pemimpinRoles.test(person.role_name)
          ); // Semua yang tidak termasuk pemimpin

          // Map to Select component format
          setPemimpinOptions(
            pemimpin.map((person) => ({ value: person.id, label: person.name }))
          );
          setPesertaOptions(
            peserta.map((person) => ({ value: person.id, label: person.name }))
          );
          setPdPelaksanaOptions(
            peserta.map((person) => ({ value: person.id, label: person.name }))
          );
        } else {
          console.error("Error fetching people data:", result.message);
        }
      } catch (error) {
        console.error("Error fetching people data:", error);
      }
    };

    fetchPeople();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date, name) => {
    setFormData((prev) => ({ ...prev, [name]: date }));
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, fileUndangan: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setLoading(true);

    const { tanggal, waktuMulai, waktuSelesai, fileUndangan } = formData;

    const startDateTime = new Date(tanggal);
    startDateTime.setHours(waktuMulai.getHours(), waktuMulai.getMinutes());

    const endDateTime = new Date(tanggal);
    endDateTime.setHours(waktuSelesai.getHours(), waktuSelesai.getMinutes());

    if (startDateTime >= endDateTime) {
      setErrorMessage("Waktu selesai harus lebih besar dari waktu mulai.");
      setLoading(false);
      return;
    }

    if (fileUndangan) {
      const allowedExtensions = /(\.pdf|\.doc|\.docx)$/i;
      if (!allowedExtensions.exec(fileUndangan.name)) {
        setErrorMessage("File undangan harus berformat PDF, DOC, atau DOCX.");
        setLoading(false);
        return;
      } else if (fileUndangan.size > 2 * 1024 * 1024) {
        setErrorMessage("Ukuran file undangan tidak boleh lebih dari 2MB.");
        setLoading(false);
        return;
      }
    } else {
      setErrorMessage("File undangan harus diupload.");
      setLoading(false);
      return;
    }

    // Pastikan format tanggal sesuai dengan MySQL
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:00`;
    };

    const formattedTanggal = formatDate(tanggal);
    const formattedWaktuMulai = formatDate(waktuMulai);
    const formattedWaktuSelesai = formatDate(waktuSelesai);

    // Kirim data
    const formDataToSend = new FormData();
    formDataToSend.append("nomorUndangan", formData.nomorUndangan);
    formDataToSend.append("tanggal", formattedTanggal);
    formDataToSend.append("waktuMulai", formattedWaktuMulai);
    formDataToSend.append("waktuSelesai", formattedWaktuSelesai);
    formDataToSend.append("tempat", formData.tempat);
    formDataToSend.append("agenda", formData.agenda);
    formDataToSend.append("pdPelaksana", formData.pdPelaksana);
    formDataToSend.append("pemimpinRapat", formData.pemimpinRapat);
    formDataToSend.append(
      "peserta",
      JSON.stringify(formData.peserta.map((p) => p.value))
    );
    formDataToSend.append("fileUndangan", fileUndangan);

    try {
      const response = await fetch("https://api.simad.adagempa.cloud/api/invitations", {
        method: "POST",
        body: formDataToSend,
      });

      // Tambahkan log untuk response
      const responseData = await response.json();
      console.log(responseData);

      if (response.ok) {
        Swal.fire(
          "Undangan berhasil dikirim",
          "Silakan melihat detail pada kalender",
          "success",
          2000,
          false
        );
        // Reset form setelah sukses
        setFormData({
          nomorUndangan: "",
          tanggal: "",
          waktuMulai: "",
          waktuSelesai: "",
          tempat: "",
          agenda: "",
          pdPelaksana: "",
          pemimpinRapat: "",
          peserta: [],
          fileUndangan: null,
        });
      } // Cek jika respons adalah 409 Conflict
      else if (response.status === 409) {
        // alert("Konflik Jadwal: Terdapat jadwal yang tumpang tindih.");
        Swal.fire({
          title: "Konflik Jadwal",
          text: "Terdapat jadwal yang tumpang tindih. Apakah Anda yakin ingin tetap melanjutkan?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya, tetap lanjut",
          cancelButtonText: "Batal",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire(
              "Lanjutkan!",
              "Proses tetap dilanjutkan meskipun ada konflik (LOGIKA BELUM DIIMPLEMENTASIKAN PADA SISI BACKEND, TIDAK ADA DIPROSES).",
              "success"
            );
          } else {
            Swal.fire("Dibatalkan", "Proses dibatalkan oleh pengguna.", "info");
          }
        });
        // return;
      } else {
        setErrorMessage("Terjadi kesalahan saat mengirim undangan");
      }
    } catch (error) {
      console.error("Terjadi kesalahan:", error);
      setErrorMessage("Terjadi kesalahan saat mengirim undangan");
    } finally {
      setLoading(false);
    }
  };

  const inputClassName =
    "block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6";

  // Custom styles for react-datepicker
  const datePickerWrapperClassName = "react-datepicker-wrapper w-full";
  const datePickerClassName = "react-datepicker__input-container";
  const datePickerInputClassName = `${inputClassName} w-full`;

  return (
    <div
      className={`min-h-screen flex items-center justify-center ${
        context === "admin" ? "mt-4" : "mt-28"
      }`}
    >
      <div className={wrapperClass}>
        {showHeading && (
          <h1 className="mb-6 text-3xl sm:text-4xl md:text-5xl font-bold text-center">
            MASUKKAN JADWAL RAPAT
          </h1>
        )}
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded-lg p-6 sm:p-8 md:p-10"
        >
          {errorMessage && (
            <div className="mb-4 text-red-600">{errorMessage}</div>
          )}
          <div className="space-y-6">
            <div className="col-span-full mb-4">
              <label
                htmlFor="nomorUndangan"
                className="block text-sm font-medium leading-6 text-gray-900 text-start"
              >
                Nomor Undangan
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="nomorUndangan"
                  name="nomorUndangan"
                  value={formData.nomorUndangan}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="col-span-full mb-4">
              <label
                htmlFor="tanggal"
                className="block text-sm font-medium leading-6 text-gray-900 text-start"
              >
                Tanggal
              </label>
              <div className="mt-2">
                <DatePicker
                  selected={formData.tanggal}
                  onChange={(date) => handleDateChange(date, "tanggal")}
                  dateFormat="dd/MM/yyyy"
                  wrapperClassName={datePickerWrapperClassName}
                  className={datePickerInputClassName}
                  calendarClassName={datePickerClassName}
                  required
                />
              </div>
            </div>

            <div className="col-span-full mb-4">
              <label
                htmlFor="waktuMulai"
                className="block text-sm font-medium leading-6 text-gray-900 text-start"
              >
                Waktu Mulai
              </label>
              <div className="mt-2">
                <DatePicker
                  selected={formData.waktuMulai}
                  onChange={(date) => handleDateChange(date, "waktuMulai")}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  wrapperClassName={datePickerWrapperClassName}
                  className={datePickerInputClassName}
                  calendarClassName={datePickerClassName}
                  required
                />
              </div>
            </div>

            <div className="col-span-full mb-4">
              <label
                htmlFor="waktuSelesai"
                className="block text-sm font-medium leading-6 text-gray-900 text-start"
              >
                Waktu Selesai
              </label>
              <div className="mt-2">
                <DatePicker
                  selected={formData.waktuSelesai}
                  onChange={(date) => handleDateChange(date, "waktuSelesai")}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  wrapperClassName={datePickerWrapperClassName}
                  className={datePickerInputClassName}
                  calendarClassName={datePickerClassName}
                  required
                />
              </div>
            </div>

            <div className="col-span-full mb-4">
              <label
                htmlFor="tempat"
                className="block text-sm font-medium leading-6 text-gray-900 text-start"
              >
                Tempat
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="tempat"
                  name="tempat"
                  value={formData.tempat}
                  onChange={handleInputChange}
                  required
                  autoComplete="off"
                  className={inputClassName}
                />
              </div>
            </div>

            <div className="col-span-full mb-4">
              <label
                htmlFor="agenda"
                className="block text-sm font-medium leading-6 text-gray-900 text-start"
              >
                Agenda
              </label>
              <div className="mt-2">
                <textarea
                  id="agenda"
                  name="agenda"
                  rows={3}
                  value={formData.agenda}
                  onChange={handleInputChange}
                  required
                  autoComplete="off"
                  className={inputClassName}
                />
              </div>
            </div>

            <div className="col-span-full mb-4">
              <label
                htmlFor="pdPelaksana"
                className="block text-sm font-medium leading-6 text-gray-900 text-start"
              >
                PD Pelaksana Undangan
              </label>
              <div className="mt-2">
                <div className="mt-2">
                  <select
                    id="pdPelaksana"
                    name="pdPelaksana"
                    value={formData.pdPelaksana}
                    onChange={handleInputChange}
                    required
                    autoComplete="off"
                    className={inputClassName}
                  >
                    <option value="">Pilih PD Pelaksana</option>
                    {pdPelaksanaOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="col-span-full mb-4">
              <label
                htmlFor="pemimpinRapat"
                className="block text-sm font-medium leading-6 text-gray-900 text-start"
              >
                Pemimpin Rapat
              </label>
              <div className="mt-2">
                <select
                  id="pemimpinRapat"
                  name="pemimpinRapat"
                  value={formData.pemimpinRapat}
                  onChange={handleInputChange}
                  required
                  autoComplete="off"
                  className={inputClassName}
                >
                  <option value="">Pilih Pemimpin</option>
                  {pemimpinOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-span-full mb-4">
              <label
                htmlFor="peserta"
                className="block text-sm font-medium leading-6 text-gray-900 text-start"
              >
                Peserta Rapat
              </label>
              <div className="mt-2">
                <Select
                  isMulti
                  options={pesertaOptions}
                  value={formData.peserta}
                  onChange={(selectedOptions) =>
                    setFormData((prev) => ({
                      ...prev,
                      peserta: selectedOptions,
                    }))
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            </div>

            <div className="col-span-full mb-4">
              <label
                htmlFor="fileUndangan"
                className="block text-sm font-medium leading-6 text-gray-900 text-start"
              >
                File Dokumen Undangan
              </label>
              <div className="mt-2">
                <input
                  type="file"
                  id="fileUndangan"
                  name="fileUndangan"
                  onChange={handleFileChange}
                  className={inputClassName}
                  accept=".pdf,.doc,.docx"
                  required
                />
              </div>
            </div>
          </div>

          <div className="mt-8">
            <button
              type="submit"
              className="w-full rounded bg-indigo-600 px-4 py-2 text-white font-medium hover:bg-indigo-700 text-center"
              disabled={loading}
            >
              {loading ? "Mengirim..." : "Kirim Undangan"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MeetingForm;
